import { getGlobalT } from '../../../i18n/setup-translations';
import {
    formatAmountWithCurrency,
    normalizePriceAmount,
} from '../../../utils/normalize-price-amount';
import { availablePeriods, ISubscriptionOffer } from '../interfaces';

import { IRawSubscriptionVariantOffer } from '../interfaces-api';

const t = getGlobalT();

export const getBestOfferId = (
    variants: IRawSubscriptionVariantOffer[],
): number | null =>
    variants.reduce<{
        id: number | null;
        monthlyPrice: number | null;
    }>(
        (acc, variant) => {
            if (!acc.monthlyPrice || acc.monthlyPrice > variant.amount_month) {
                return {
                    id: variant.id,
                    monthlyPrice: variant.amount_month,
                };
            }

            return acc;
        },
        { id: null, monthlyPrice: null },
    ).id;

export const mapCodeToBilledText = (variantCode: string) => {
    const translations = new Map<string, string>([
        ['1_month_1_tracker', t('registerSummaryTable:BILLED_MONTH')],
        ['12_month_1_tracker', t('registerSummaryTable:BILLED_YEAR')],
        ['24_month_1_tracker', t('registerSummaryTable:BILLED_TWO_YEARS')],
        ['36_month_1_tracker', t('registerSummaryTable:BILLED_THREE_YEARS')],
    ]);

    return (
        translations.get(variantCode) || t('registerSummaryTable:BILLED_YEAR')
    );
};

/**
 * ID from API, which should be selected by default. Typically it will be
 * the most desirable offer Weenect wants to sell
 */
const defaultSelectedCode = '36_month_1_tracker';

type periodMap = Record<availablePeriods, string>;

export const getPlanName = (code: string) => {
    switch (code) {
        case '1_month_1_tracker':
            return t('subscriptions:PLAN_NAMES.ONE_MONTH');
        case '12_month_1_tracker':
            return t('subscriptions:PLAN_NAMES.ONE_YEAR');
        case '24_month_1_tracker':
            return t('subscriptions:PLAN_NAMES.TWO_YEARS');
        case '36_month_1_tracker':
            return t('subscriptions:PLAN_NAMES.THREE_YEARS');
        default:
            return 'Plan';
    }
};

export const mapRawSubscriptionOffersVariants = (
    variants: IRawSubscriptionVariantOffer[],
): ISubscriptionOffer[] => {
    /**
     * String which maps saving period for display saving amont.
     * For example 12 months = saving 35€ / year, 24 months = saving 52 € / year.
     */
    const savingPerPeriodMap: periodMap = {
        1: t('periods:BASE.MONTH'),
        12: t('periods:BASE.YEAR'),
        36: t('periods:ALT.YEAR_plural', { count: 3 }),
    };

    /**
     * Option variant name
     */
    const formattedPeriodMapping: periodMap = {
        1: t('periods:ALT.MONTH'),
        12: t('periods:ALT.YEAR', { count: 1 }),
        36: t('periods:ALT.YEAR_plural', { count: 3 }),
    };

    /**
     * This should likely be on the backend side, since it's totally
     * a business logic.
     */
    const getInfo = (
        variant: IRawSubscriptionVariantOffer,
    ): Array<string> | null => {
        if (variant.period === 1) {
            return [
                t('registerSummaryTable:BILLED_MONTH'),
                t('subscriptionSelection:MIN_THREE_MONTHS'),
                t('subscriptionSelection:CANCELABLE_AFTER_THREE_MONTHS'),
            ];
        } else if (variant.period === 12) {
            return [
                t('registerSummaryTable:BILLED_YEAR'),
                t('subscriptionSelection:PAYMENT_MEANS'),
                t('subscriptionSelection:CANCEL_ANYTIME'),
            ];
        }

        else if (variant.period === 36) {
            return [
                t('registerSummaryTable:BILLED_THREE_YEARS'),
                t('subscriptionSelection:PAYMENT_MEANS'),
                t('subscriptionSelection:CANCEL_ANYTIME'),
            ];
        }
        return [];
    };
    const getSavingString = (
        variant: IRawSubscriptionVariantOffer,
    ): string | null => {
        if (variant.period === 1) {
            return null;
        } else if (variant.period === 12) {
            return t('subscriptions:OFFERS.SAVING_PER_YEAR', {
                amount: formatAmountWithCurrency(
                    variant.saving,
                    variant.currency,
                ),
            }) as string;
        }
        else if (variant.period === 36) {
            /**
             * Divide by 3 because saving price is counted per 3 years
             */
            return t('subscriptions:OFFERS.SAVING_PER_YEAR', {
                amount: formatAmountWithCurrency(
                    variant.saving / 3,
                    variant.currency,
                ),
            }) as string;
        }
        return null;
    };

    const bestOffer: number | null = getBestOfferId(variants);

    // The hasCommitment status is only defined in 'front' side.
    // So this is aimed to evolve in the future.
    const getHasCommitment = (variant: IRawSubscriptionVariantOffer) => {
        if (variant.code === '1_month_1_tracker') {
            return true;
        }
        return false;
    };
    const getHasCommitmentText = (variant: IRawSubscriptionVariantOffer) => {
        if (variant.code === '1_month_1_tracker') {
            return t('subscriptionSelection:CANCELABLE_AFTER_THREE_MONTHS');
        }
        return null;
    };

    return variants.map(
        (variant): ISubscriptionOffer => {
            return {
                isBestChoice: bestOffer === variant.id,
                hasCommitment: getHasCommitment(variant),
                hasCommitmentText: getHasCommitmentText(variant),
                defaultSelected: defaultSelectedCode === variant.code,
                currency: variant.currency,
                formattedPrice: formatAmountWithCurrency(
                    variant.amount,
                    variant.currency,
                ),
                amountMonth: formatAmountWithCurrency(
                    variant.amount_month,
                    variant.currency,
                ),
                pricePer:
                    savingPerPeriodMap[variant.period as availablePeriods],
                formattedPeriod:
                    formattedPeriodMapping[variant.period as availablePeriods],
                saving: getSavingString(variant),
                info: getInfo(variant),
                id: variant.id,
                code: variant.code,
                rawPrice: normalizePriceAmount(variant.amount),
                pricePerYear: normalizePriceAmount(
                    (variant.amount / variant.period) * 12,
                ),
                planName: getPlanName(variant.code),
            };
        },
    );
};
