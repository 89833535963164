import { get } from 'lodash';
import moment from 'moment';

import { getGlobalT } from '../../../i18n/setup-translations';
import { ISubscriptionDetails } from '../../user-subscriptions/interfaces';
import { SubscriptionOptionType } from '../../user-subscriptions/interfaces-api';
import { IPaymentHistoryItem } from '../interfaces';
import { IRawSubscriptionPaymentHistoryItem } from '../interfaces-api';
import { getInvoiceUrl } from '../services/account.service';
import { getPlanName } from '../../subscription-offers/mappers/map-subscription-offers-variants'
export const mapToPaymentHistoryItem = (
    /**
     * rawPaymentItem contains subscription and options payments
     */
    rawPaymentItem: IRawSubscriptionPaymentHistoryItem,
    userSubscriptions: ISubscriptionDetails[],
): IPaymentHistoryItem => {
    const t = getGlobalT();

    const relatedSubscription = userSubscriptions.find(
        (sub) => sub.id === rawPaymentItem.subscription_id,
    ) as ISubscriptionDetails;

    const subscriptionName = getPlanName(rawPaymentItem.offer.code);

    const getInvoice = () => {
        if (rawPaymentItem.status === 'success') {
            return getInvoiceUrl(rawPaymentItem.payment_id);
        }
    };

    const data: IPaymentHistoryItem = {
        date: moment(rawPaymentItem.date).format('D MMM YYYY'),
        id: rawPaymentItem.id,
        invoiceUrl: getInvoice(),
        paymentId: rawPaymentItem.payment_id,
        status: rawPaymentItem.status,
        paymentName: subscriptionName,
    };

    if (rawPaymentItem.codes && rawPaymentItem.codes.length > 0) {
        data.code = rawPaymentItem.codes;

        if (data.code.length === 1) {
            if (data.code[0] === SubscriptionOptionType.SMS) {
                data.paymentName = t('subOptions:SMS_PACK.BASE');
            } else if (data.code[0] === SubscriptionOptionType.WARRANTY) {
                data.paymentName = t('subOptions:WARRANTY.BASE');
            } else {
                data.paymentName = t('subOptions:UNDEFINED.PAYMENTS');
            }
        } else {
            // Two options for that payment
            data.paymentName =
                t('subOptions:SMS_PACK.BASE') +
                ' & ' +
                t('subOptions:WARRANTY.BASE');
        }
    }

    return data;
};
